<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.45236 13.0152L11.2713 11.3929C11.4337 11.3661 11.5725 11.2611 11.6425 11.1121L15.8703 2.10425L20.448 10.94C20.5237 11.0861 20.6664 11.1857 20.8297 11.2061L30.7036 12.4446L23.7152 19.5276C23.5996 19.6447 23.5491 19.8112 23.58 19.9728L25.452 29.7466L16.5555 25.288C16.4084 25.2143 16.2344 25.2177 16.0903 25.2971L7.37549 30.0979L8.86546 20.2599C8.8901 20.0972 8.83309 19.9328 8.71301 19.8202L1.45236 13.0152Z"
      stroke="#0050FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
